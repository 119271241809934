import { gql } from '../../../../../hooks'

export const GET_SAMPLE_LOG = gql`
  query SampleLogs($id: ID!) {
    t6SampleLogs(id: $id) {
    }
  }
`

export const NULL_SAMPLE = gql`
  mutation NullSample($id: ID!) {
    t6NullSample(id: $id) {
    }
  }
`

export const REJECT_SAMPLE = gql`
  mutation RejectSample($id: ID!) {
    t6RejectSample(id: $id) {
    }
  }
`

export const BACK_TO_RECEIVED_SAMPLE = gql`
  mutation BackToReceivedSample($id: ID!) {
    t6BackToReceivedSample(id: $id) {
    }
  }
`

export const BACK_TO_ENTERED_SAMPLE = gql`
  mutation BackToEnteredSample($id: ID!) {
    t6BackToEnteredSample(id: $id) {
    }
  }
`

export const BACK_TO_ISCAA_SAMPLE = gql`
  mutation BackToIscaaSample($id: ID!) {
    t6BackToIscaaSample(id: $id) {
    }
  }
`
