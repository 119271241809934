import React from 'react'
import { Collapse } from 'react-bootstrap'
import { Redirect, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faDesktop,
  faBoxOpen,
  faVial,
  faVials,
  faPeopleCarry,
  faPencilAlt,
  faStickyNote,
  faList,
  faChartBar,
  faUser,
  faCogs,
  faOilCan,
  faTruck,
  faHeartbeat,
  faCheckSquare,
  faProjectDiagram,
  faMoneyBillAlt,
  faWrench,
  faSitemap,
  faStopwatch,
  faChevronDown,
  faChevronUp,
  faUserFriends,
  faFileAlt,
  faClipboardList,
  faFileCsv,
  faTimesCircle,
  faClipboard,
} from '@fortawesome/free-solid-svg-icons'
// import './style.css'

// import logo from './img/tribo-logo-2.png'
import logo from './img/coddi.png'

// Global reducer
import { useGlobal, useState } from '../../../../../../hooks'
import config from '../../../../../../config'

const { ENV } = config

/****************************************************************************
 *
 * Menu Block
 *
 ****************************************************************************/

const MenuBlock = ({
  title,
  children,
  roles = [],
  clients = [],
  laboratories = [],
  users = [],
}) => {
  const [{ me }] = useGlobal()

  const [open, setOpen] = useState(true)

  if (me.id_role !== 1) {
    if (roles.length && !roles.includes(me.id_role)) return <></>
    if (users.length && !users.includes(me.username)) return <></>
  }

  if (clients.length && !clients.includes(parseInt(me.id_client))) return <></>

  if (laboratories.length && !laboratories.includes(parseInt(me.id_laboratory)))
    return <></>

  return (
    <>
      <div
        className="d-flex justify-content-end px-3 py-2 lead border-bottom bg-gray"
        onClick={() => setOpen(!open)}
        role="button"
        aria-expanded={open}
      >
        <div className="mr-auto ">{title}</div>
        <FontAwesomeIcon
          className="align-self-center"
          color="#6c757d"
          size="xs"
          icon={open ? faChevronUp : faChevronDown}
        />
      </div>
      <Collapse in={open}>
        <div>{children}</div>
      </Collapse>
    </>
  )
}

/****************************************************************************
 *
 * Menu Item
 *
 ****************************************************************************/

const MenuItem = ({
  to,
  name,
  roles = [],
  clients = [],
  laboratories = [],
}) => {
  const [{ me }] = useGlobal()

  const style = {
    fontSize: '14px',
  }

  if (me.id_role !== 1) {
    if (roles.length && !roles.includes(me.id_role)) return <></>
  }

  if (clients.length && !clients.includes(parseInt(me.id_client))) return <></>

  if (laboratories.length && !laboratories.includes(parseInt(me.id_laboratory)))
    return <></>

  return (
    <Link
      to={to}
      className="d-block pl-3 py-2 bg-white border-bottom font-weight-light text-stng-darker text-decoration-none"
      style={style}
    >
      {name}
    </Link>
  )
}

/****************************************************************************
 *
 * Main render
 *
 ****************************************************************************/

const UIWrapperPageFrameSideMenu = ({ toggle }) => {
  const [{ me }] = useGlobal()

  // const history = useHistory()

  // if (!me) history.push(`/login`)
  if (!me) return <Redirect to={`/login`} />

  const style = { height: '56px' }

  const IconText = ({ icon, text }) => (
    <>
      <FontAwesomeIcon size="1x" icon={icon} color="#999999" className="mr-2" />
      {text}
    </>
  )

  // 1 | Root
  // 2 | Administrador
  // 3 | Analista
  // 4 | Ingeniero
  // 5 | Cliente

  return (
    <nav
      id="sideBarMenu"
      className="bg-light border-right"
      style={
        toggle
          ? { width: '250px', opacity: '1' }
          : { width: '0px', opacity: '0' }
      }
    >
      <div className="sidebar-header bg-stng p-0 m-0" style={style}>
        <Link to={`/`}>
          <img alt="logo" src={logo} style={{ ...style }} />
        </Link>
      </div>
      <MenuBlock
        title="Tribología"
        roles={[1, 2, 3, 4, 6, 7, 8]}
        clients={[]}
        laboratories={[]}
      >
        <MenuItem
          name={<IconText icon={faVial} text="Ingreso de muestras" />}
          to="/tribology/samples/insert"
          roles={[1, 2, 3, 4, 5, 6, 7]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faVials} text="Muestras masivas" />}
          to="/tribology/samples/insert/massive"
          roles={[1, 2, 3, 4, 5, 6, 7]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faVials} text="ISCAA" />}
          to="/tribology/samples/insert/iscaa"
          roles={[1, 2, 3, 4, 5, 6, 7]}
          clients={[]}
          laboratories={[1, 2, 4]}
        />
        <MenuItem
          name={<IconText icon={faPeopleCarry} text="Recepción de muestras" />}
          to="/tribology/samples/recipt"
          roles={[1, 2, 3, 4]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faPencilAlt} text="Ingreso de resultados" />}
          to="/tribology/samples/results"
          roles={[1, 2, 3, 4]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faFileCsv} text="Resultados masivos" />}
          to="/tribology/samples/results/massive"
          roles={[1, 2, 3, 4]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faStickyNote} text="Propuesta de informe" />}
          to="/tribology/samples/report"
          roles={[1, 2, 3, 4]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faList} text="Listado de muestras" />}
          to="/tribology/samples/search"
          roles={[1, 2, 3, 4, 8]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faClipboardList} text="Cambio de turno" />}
          to="/tribology/samples/shift_change"
          roles={
            ['antofagasta', 'centinela'].includes(me.laboratory)
              ? [1, 2, 3, 4]
              : [1, 2, 3]
          }
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faVial} text="Re-Muestreos" />}
          to="/reports/re_sampled"
          roles={[1, 2, 3, 4, 5, 6, 7]}
          clients={[]}
          laboratories={[1, 2, 4]}
        />
        {/*<MenuItem
          name={<IconText icon={faOilCan} text="Lubricantes" />}
          to="/substance"
          roles={[1, 2, 3, 4]}
          clients={[]}
          laboratories={[]}
        />*/}
      </MenuBlock>
      {/* --------------------------------------------- */}

      <MenuBlock
        title="Inspecciones"
        roles={[1, 2, 3, 4, 6, 7, 8, 9]}
        clients={[]}
        laboratories={[1, 3, 4]}
      >
        <MenuItem
          name={<IconText icon={faClipboard} text="Terreno" />}
          to="/inspections/field"
          roles={[1, 2, 3, 4, 5, 6, 7, 9]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faDesktop} text="Escritorio" />}
          to="/inspections/desktop"
          roles={[1, 2, 3, 4, 5, 6, 7, 9]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faFile} text="Informe" />}
          to="/inspections/report"
          roles={[1, 2, 3, 4, 5, 6, 7, 9]}
          clients={[]}
          laboratories={[]}
        />
      </MenuBlock>

      {/* --------------------------------------------- */}
      <MenuBlock
        title="Administración"
        roles={[1, 2, 4]}
        clients={[]}
        laboratories={[]}
      >
        <MenuItem
          name={<IconText icon={faChartBar} text="Análisis" />}
          to="/administration/analysis"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faUser} text="Cliente" />}
          to="/administration/client"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faCogs} text="Componente" />}
          to="/administration/component"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faOilCan} text="Lubricante" />}
          to="/administration/lubricant"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faTruck} text="Equipo" />}
          to="/administration/machine"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faVials} text="Muestreo" />}
          to="/administration/sampling"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faUserFriends} text="Usuarios" />}
          to="/administration/user"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faFileAlt} text="Otros" />}
          to="/administration/other"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
      </MenuBlock>
      {/* --------------------------------------------- */}
      {/*<MenuBlock
        title="Dashboards"
        roles={[1, 2, 3, 4, 5]}
        clients={[]}
        laboratories={[]}
      >
        <MenuItem
          name={<IconText icon={faChartBar} text="Cummins" />}
          to="/dashboards/cummins"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faChartBar} text="Los Pelambres" />}
          to="/dashboards/pelambres"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
      </MenuBlock>*/}
      {/* --------------------------------------------- */}
      <MenuBlock
        title="Reportes"
        roles={[1, 2, 3, 4, 5, 7]}
        clients={[]}
        laboratories={[]}
      >
        <MenuItem
          name={<IconText icon={faHeartbeat} text="Crítico" />}
          to="/reports/critic"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faCheckSquare} text="Estado de Flotas" />}
          to="/reports/fleet_state"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faProjectDiagram} text="Datos" />}
          to="/reports/data"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faMoneyBillAlt} text="Facturación" />}
          to="/reports/invoicing"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faWrench} text="Fallas" />}
          to="/reports/fails"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faSitemap} text="Gestión" />}
          to="/reports/managing"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faStopwatch} text="KPI Laboratorio" />}
          to="/reports/laboratory_kpi"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faChartBar} text="PowerBI" />}
          to="/reports/power_bi"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faBoxOpen} text="Concatenador" />}
          to="/reports/concatenator"
          roles={[]}
          clients={[]}
          laboratories={[1, 2]}
        />
        <MenuItem
          name={<IconText icon={faTimesCircle} text="Sin Muestrear" />}
          to="/reports/unsampled"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
      </MenuBlock>
      {/* --------------------------------------------- */}
      {/*}<MenuBlock
        title="Cambio turno"
        roles={[1, 2, 3, 4]}
        clients={[]}
        laboratories={[]}
      >
        <MenuItem
          name={<IconText icon={faClipboardList} text="Ingenieros" />}
          to="/shift_change/engineers"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faClipboardList} text="Analistas" />}
          to="/shift_change/analysts"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
        <MenuItem
          name={<IconText icon={faClipboardList} text="Equpos" />}
          to="/shift_change/machines"
          roles={[]}
          clients={[]}
          laboratories={[]}
        />
      </MenuBlock>*/}
    </nav>
  )
}

export default UIWrapperPageFrameSideMenu
