import React, { forwardRef } from 'react'
import { InputGroup } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { es } from 'date-fns/esm/locale'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons'

import UIButton from '../../../../UI/Button'

import {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useTrigger,
} from '../../../../../hooks'

import { useFormContext } from '../..'

import Input from '..'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'

const UIFormInputDate = forwardRef((props, ref) => {
  registerLocale('es', es)

  const {
    value: propValue,
    initialValue: propInitialValue,
    defaultValue: propDefaultValue,
    showTime: propShowTime = false,
    minDate: propMinDate = new Date('2009-1-1 00:00:00'),
    maxDate: propMaxDate = new Date(),
    highlightDates: propHighlightDates = [new Date()],
    onChange: propOnChange,
    onReset: propOnReset,
    ...rest
  } = props

  const isFirstRender = useRef(true)

  const context = useFormContext()

  const [date, setDateState] = useState(
    propValue !== undefined
      ? new Date(propValue)
      : propInitialValue !== undefined
      ? new Date(propInitialValue)
      : propDefaultValue !== undefined
      ? new Date(propDefaultValue)
      : context.initialValues[props.name] !== undefined
      ? new Date(context.initialValues[props.name])
      : context.defaultValues[props.name] != undefined
      ? new Date(context.defaultValues[props.name])
      : '',
    // : new Date(),
  )

  useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false
    else setDateState(propValue && new Date(propValue))
  }, [propValue])

  const defaultDate = useMemo(
    () =>
      propDefaultValue !== undefined
        ? new Date(propDefaultValue)
        : context.defaultValues[props.name] !== undefined
        ? new Date(context.defaultValues[props.name])
        : '',
    // : new Date(),
    [propDefaultValue, props.name, propShowTime],
  )

  const [valueTrigger, setValueTrigger] = useTrigger()
  const [errorTrigger, setErrorTrigger] = useTrigger()

  const handleChange = (date2) => {
    //  if (date !== date2) {
    setDateState(date2)
    setErrorTrigger()
    propOnChange && propOnChange(date2)
    //  }
  }

  const handleReset = () => {
    setDateState(defaultDate)
    setValueTrigger()
    propOnReset && propOnReset()
  }

  const handleDelete = () => {
    setDateState('')
    setValueTrigger()
  }

  // --------------------------------------------------------------------------
  const CustomInput = useCallback(
    forwardRef((props, ref) => {
      const CustomInput = forwardRef((props, ref) => (
        <>
          <InputGroup size="sm">
            <input ref={ref} {...props} />
            <InputGroup.Append>
              <UIButton
                disabled={props.value === ''}
                onClick={handleDelete}
                variant="outline-secondary font-weight-light"
              >
                <FontAwesomeIcon
                  className="align-self-center"
                  size="xs"
                  icon={faTrash}
                />
              </UIButton>
            </InputGroup.Append>
          </InputGroup>
        </>
      ))

      return <DatePicker ref={ref} {...props} customInput={<CustomInput />} />
    }),
    [],
  )

  // --------------------------------------------------------------------------
  const customHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    const fromYear = parseInt(moment(propMinDate).format('yyyy'))
    const toYear = parseInt(moment(propMaxDate).format('yyyy'))

    // WTF Safari???
    let years = [
      2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
      2021, 2022, 2023, 2024, 2025,
    ]

    // for (let i = fromYear; i <= toYear; i++) {
    //   years.push(i)
    // }

    // = Array(toYear - fromYear + 1)
    // .fill()
    // .map((_, idx) => fromYear + idx)

    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]

    return (
      <div
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <UIButton
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className="px-2 mr-1"
          variant="secondary"
        >
          <FontAwesomeIcon
            className="align-self-center"
            size="sm"
            icon={faAngleLeft}
          />
        </UIButton>
        <UIFormInputSelect
          value={moment(date).format('yyyy')}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </UIFormInputSelect>

        <UIFormInputSelect
          value={months[parseInt(moment(date).format('MM')) - 1]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </UIFormInputSelect>
        <UIButton
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className="px-2 ml-1"
          variant="secondary"
        >
          <FontAwesomeIcon
            className="align-self-center"
            size="sm"
            icon={faAngleRight}
          />
        </UIButton>
      </div>
    )
  }

  // --------------------------------------------------------------------------
  return (
    <Input
      {...rest}
      ref={ref}
      type="date"
      selected={date}
      value={
        date === ''
          ? ''
          : moment(date).format(
              propShowTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD',
            )
      }
      customInput={CustomInput}
      onReset={handleReset}
      locale="es"
      dateFormat={propShowTime ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy'}
      onChange={handleChange}
      minDate={propMinDate}
      maxDate={propMaxDate}
      highlightDates={propHighlightDates}
      shouldCloseOnSelect={!propShowTime}
      showTimeInput={!!propShowTime}
      triggerValueCheck={valueTrigger}
      triggerErrorCheck={errorTrigger}
      renderCustomHeader={customHeader}
    />
  )
})

export default UIFormInputDate
