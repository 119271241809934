import React from 'react'
import moment from 'moment'
import { Col, Row, Form, Modal } from 'react-bootstrap'

// Hooks
import { useState, useQuery, useMutation } from '../../../../../hooks'

// Components
import UIPanelInfo from '../../../../UI/Panel/Info'
import UIPanelInfoBlock from '../../../../UI/Panel/Info/Block'
import UIPanelInfoItem from '../../../../UI/Panel/Info/Item'
import UITable from '../../../../UI/Table'
import UITableHead from '../../../../UI/Table/Head'
import UITableBody from '../../../../UI/Table/Body'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIForm from '../../../../UI/FormReload'
import UIButton from '../../../../UI/Button'

// Queries
import {
  GET_SAMPLE_LOG,
  NULL_SAMPLE,
  REJECT_SAMPLE,
  BACK_TO_RECEIVED_SAMPLE,
  BACK_TO_ENTERED_SAMPLE,
  BACK_TO_ISCAA_SAMPLE,
} from './queries'

const TribologySamplesSearchInfo = ({ id }) => {
  // Modal delete confirmation
  const [showModal, setShowModalState] = useState(false)

  // --------------------------------------------------------------------------
  //
  // Query: Received samples info
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_SAMPLE_LOG, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation
  //
  // --------------------------------------------------------------------------

  const [t6BackToIscaaSample] = useMutation(BACK_TO_ISCAA_SAMPLE, {
    // update: (cache, { data: { t6BackToIscaaSample } }) => {
    //   // Get anomalies
    //   const { t6SampleLogs } = cache.readQuery({
    //     query: GET_SAMPLE_LOG,
    //     variables: { id },
    //   })

    //   const { info, logs } = t6SampleLogs.data

    //   // Write the cache
    //   cache.writeQuery({
    //     query: GET_SAMPLE_LOG,
    //     variables: { id },
    //     data: {
    //       t6SampleLogs: {
    //         data: { info, logs: [...logs, t6DeleteSample.data] },
    //       },
    //     },
    //   })
    // },
    onSuccessMessage: 'La muestra se devolvió a ISCAA con éxito',
    onErrorMessage: 'La muestra no pudo ser devuelta a ISCAA',
  })

  const [t6BackToEnteredSample] = useMutation(BACK_TO_ENTERED_SAMPLE, {
    update: (cache, { data: { t6BackToEnteredSample } }) => {
      // Get anomalies
      const { t6SampleLogs } = cache.readQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
      })

      const { info, logs } = t6SampleLogs.data

      // Write the cache
      cache.writeQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
        data: {
          t6SampleLogs: {
            data: { info, logs: [...logs, t6BackToEnteredSample.data] },
          },
        },
      })
    },
    onSuccessMessage: 'La muestra se devolvió a ingresada con éxito',
    onErrorMessage: 'La muestra no pudo ser devuelta a ingresada',
  })

  const [t6BackToReceivedSample] = useMutation(BACK_TO_RECEIVED_SAMPLE, {
    update: (cache, { data: { t6BackToReceivedSample } }) => {
      // Get anomalies
      const { t6SampleLogs } = cache.readQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
      })

      const { info, logs } = t6SampleLogs.data

      // Write the cache
      cache.writeQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
        data: {
          t6SampleLogs: {
            data: { info, logs: [...logs, t6BackToReceivedSample.data] },
          },
        },
      })
    },
    onSuccessMessage: 'La muestra se devolvió a recivida con éxito',
    onErrorMessage: 'La muestra no pudo ser devuelta a recivida',
  })

  const [t6RejectSample] = useMutation(REJECT_SAMPLE, {
    update: (cache, { data: { t6RejectSample } }) => {
      // Get anomalies
      const { t6SampleLogs } = cache.readQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
      })

      const { info, logs } = t6SampleLogs.data

      // Write the cache
      cache.writeQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
        data: {
          t6SampleLogs: {
            data: { info, logs: [...logs, t6RejectSample.data] },
          },
        },
      })
    },
    onSuccessMessage: 'La muestra se rechazó con éxito',
    onErrorMessage: 'La muestra no pudo ser rechazada',
  })

  const [t6NullSample] = useMutation(NULL_SAMPLE, {
    update: (cache, { data: { t6NullSample } }) => {
      // Get anomalies
      const { t6SampleLogs } = cache.readQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
      })

      const { info, logs } = t6SampleLogs.data

      // Write the cache
      cache.writeQuery({
        query: GET_SAMPLE_LOG,
        variables: { id },
        data: {
          t6SampleLogs: { data: { info, logs: [...logs, t6NullSample.data] } },
        },
      })
    },
    onSuccessMessage: 'La muestra se anuló con éxito',
    onErrorMessage: 'La muestra no pudo ser anulada',
  })

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading)
    return (
      <UIPanelInfo cbackToIscaa>
        <UIPanelInfoBlock>
          <UILoading />
        </UIPanelInfoBlock>
      </UIPanelInfo>
    )

  if (data && data.t6SampleLogs) {
    const { info, logs } = data.t6SampleLogs.data

    const handleSubmit = () => {}

    // Modal handler
    const handleCloseModal = () => setShowModalState(false)
    const handleAcceptModal = ({ id, type }) => {
      console.log('handleAcceptModal: ', id, type)
      switch (type) {
        case 'null':
          t6NullSample({ variables: { id } })
          break
        case 'backToIscaa':
          console.log('switch backToIscaa', id)
          t6BackToIscaaSample({ variables: { id } })
          break
        case 'backToEntered':
          console.log('switch backToEntered', id)
          t6BackToEnteredSample({ variables: { id } })
          break
        case 'backToReceived':
          console.log('switch backToReceived', id)
          t6BackToReceivedSample({ variables: { id } })
          break
        case 'reject':
          t6RejectSample({ variables: { id } })
          break
      }

      // deleteAnomaly({ variables: { id } })
      setShowModalState(false)
    }

    // Handlers
    const handleReject = (id) => {
      setShowModalState({ id, type: 'reject' })
      // onDataChange({ action: 'info' })
    }

    const handleBackToReceived = (id) => {
      console.log('handleBackToReceived: ', id)
      setShowModalState({ id, type: 'backToReceived' })
      // onDataChange({ action: 'info' })
    }

    const handleBackToEntered = (id) => {
      console.log('handleBackToEntered: ', id)
      setShowModalState({ id, type: 'backToEntered' })
      // onDataChange({ action: 'info' })
    }

    const handleBackToIscaa = (id) => {
      console.log('handleBackToIscaa: ', id)
      setShowModalState({ id, type: 'backToIscaa' })
      // onDataChange({ action: 'info' })
    }

    const handleNull = (id) => {
      setShowModalState({ id, type: 'null' })
      // onDataChange({ action: 'info' })
    }

    console.log(logs)
    const lastIdStage = logs[logs.length - 1].id_stage
    console.log(lastIdStage)

    const isEntered = lastIdStage === 1
    const isReceived = lastIdStage === 2
    const isbackToReceived = lastIdStage === 3
    const isProposed = lastIdStage === 4
    const isRejected = lastIdStage === 5 // !!logs.filter(({ id_stage }) => id_stage === 5).length
    const isNulled = lastIdStage === 6 // !!logs.filter(({ id_stage }) => id_stage === 6).length

    return (
      <UIForm onSubmit={handleSubmit}>
        <UIPanelInfo>
          <UIPanelInfoBlock title="Historial">
            <UIPanelInfoItem name="Correlativo">{id}</UIPanelInfoItem>
            <UIPanelInfoItem name="Descripción">
              {`${info.client.name} - ${info.site.name}`}
            </UIPanelInfoItem>
            <br />
            <UITable>
              <UITableHead>
                {['Estado', 'Fecha estado', 'Responsable']}
              </UITableHead>
              <UITableBody>
                {logs.map(({ stage, date, responsible, comment }, index) => (
                  <tr key={index}>
                    <td>{stage}</td>
                    <td>
                      {date && moment(date).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td>{responsible}</td>
                  </tr>
                ))}
              </UITableBody>
            </UITable>
          </UIPanelInfoBlock>
          <UIPanelInfoBlock title="Acciones">
            <Form.Group as={Row} className="my-4">
              <Col className="d-flex">
                {isProposed && (
                  <UIButton variant="warning" onClick={() => handleReject(id)}>
                    Rechazar muestra
                  </UIButton>
                )}
                {(isbackToReceived || isRejected) && (
                  <UIButton
                    variant="warning"
                    onClick={() => handleBackToReceived(id)}
                  >
                    Volver a "recepcionada"
                  </UIButton>
                )}
                {isReceived && (
                  <UIButton
                    variant="warning"
                    onClick={() => handleBackToEntered(id)}
                  >
                    Volver a "ingresada"
                  </UIButton>
                )}
                {isEntered && (
                  <UIButton
                    variant="warning"
                    onClick={() => handleBackToIscaa(id)}
                  >
                    Volver a ISCAA
                  </UIButton>
                )}
                <UIButton
                  variant="danger"
                  className="ml-2"
                  onClick={() => handleNull(id)}
                  disabled={isNulled}
                >
                  Anular muestra
                </UIButton>
              </Col>
            </Form.Group>
          </UIPanelInfoBlock>
        </UIPanelInfo>
        <Modal show={!!showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              ¿Realmente desea{' '}
              {showModal.type === 'null'
                ? 'anular'
                : showModal.type === 'backToIscaa'
                ? 'eliminar'
                : showModal.type === 'backToEntered'
                ? 'volver al estado "ingresada"'
                : showModal.type === 'backToReceived'
                ? 'volver al estado "recepcionada"'
                : 'rechazar'}
              la muestra <strong>{showModal.id}</strong>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <UIButton variant="secondary" onClick={handleCloseModal}>
              Cancelar
            </UIButton>
            <UIButton
              variant="danger"
              onClick={() => handleAcceptModal(showModal)}
            >
              {showModal.type === 'null'
                ? 'Anular'
                : showModal.type === 'backToIscaa'
                ? 'Eliminar'
                : showModal.type === 'backToEntered'
                ? 'Volver a "ingresada"'
                : showModal.type === 'backToReceived'
                ? 'Volver a "recepcionada"'
                : 'Rechazar'}
            </UIButton>
          </Modal.Footer>
        </Modal>
      </UIForm>
    )
  }

  // Error
  return (
    <UIPanelInfo cbackToIscaa>
      <UIPanelInfoBlock>
        <UIError />
      </UIPanelInfoBlock>
    </UIPanelInfo>
  )
}

export default TribologySamplesSearchInfo
